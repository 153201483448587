import groupBy from "lodash/groupBy";
import { Url } from "next/dist/shared/lib/router/router";
import Link from "next/link";
import { useRouter } from "next/router";
import { ParsedQs } from "qs";

import { Query } from "custom-types/Query";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import ClearIcon from "components/Icons/x.svg";

import {
  AvailableFilter,
  FilterType,
  GroupFilter,
  ToggleFilterType,
} from "../DynamicFilters/types";
import { FULFILLMENT_METHODS } from "../FulfillmentFilters/FulfillmentFilters";
import {
  getUpdatedFilterHref,
  getUpdatedFulfillmentHref,
} from "../utils/filterUtils";

const FilterChipLink = ({
  children,
  href,
}: {
  children: React.ReactNode;
  href: Url;
}) => (
  <Link
    href={href}
    className="flex items-center text-xs font-bold py-xs px-sm bg-green-20 border border-green-40 rounded flex-shrink-0 capitalize"
  >
    <span className="text-left truncate-lines max-w-[200px]">{children}</span>
    <ClearIcon height="12" width="12" className="ml-xs" />
  </Link>
);

const AppliedChips: React.FC<{ availableFilters: AvailableFilter[] }> = ({
  availableFilters,
}) => {
  const { asPath } = useRouter();
  const { query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const appliedFilters = (query.filter as Query) || {};
  const appliedFulfillments = (query.fulfillment as Query) || [];

  const appliedChips = [
    ...(Array.isArray(appliedFulfillments)
      ? appliedFulfillments.map((fulfillment) => {
          const fulfillmentMethod = FULFILLMENT_METHODS.find(
            (method) => method.value === fulfillment,
          );

          if (fulfillmentMethod) {
            return {
              href: getUpdatedFulfillmentHref(asPath, fulfillmentMethod.value),
              label: fulfillmentMethod.name,
            };
          }

          return {
            href: getUpdatedFulfillmentHref(asPath, fulfillment),
            label: fulfillment,
          };
        })
      : []),
    ...availableFilters.reduce<{ href: Url; label: string }[]>(
      (chips, filter) => {
        if (!appliedFilters[filter.name]) {
          return chips;
        }

        if (filter.type === FilterType.toggle) {
          const toggleFilter = filter as ToggleFilterType;
          return [
            ...chips,
            {
              href: getUpdatedFilterHref(asPath, toggleFilter.name, "true"),
              label: toggleFilter.label,
            },
          ];
        }

        // * filter is some kind of group filter
        const groupFilter = filter as GroupFilter;
        const filterValuesMap = groupBy(groupFilter.values, "value");
        if (Array.isArray(appliedFilters[groupFilter.name])) {
          const appliedValues = appliedFilters[groupFilter.name] as string[];
          return [
            ...chips,
            ...appliedValues.map((appliedValue) => ({
              href: getUpdatedFilterHref(
                asPath,
                groupFilter.name,
                appliedValue,
              ),
              label: filterValuesMap[appliedValue]?.[0].chip || appliedValue,
            })),
          ];
        } else {
          const appliedValue = appliedFilters[groupFilter.name] as string;
          return [
            ...chips,
            {
              href: getUpdatedFilterHref(
                asPath,
                groupFilter.name,
                appliedValue,
              ),
              label: filterValuesMap[appliedValue]?.[0].chip || appliedValue,
            },
          ];
        }
      },
      [],
    ),
  ];

  return (
    <>
      {appliedChips.map(({ label, href }, i) => (
        <FilterChipLink key={`${label}_${i}`} href={href}>
          {label}
        </FilterChipLink>
      ))}
    </>
  );
};

export default AppliedChips;
