import { stringify } from "qs";

import { Query } from "custom-types/Query";

const createPaginationUrl = (
  path: string,
  query: Query,
  pageInPath?: boolean,
  page?: number | string,
) => {
  const pathName = pageInPath ? path.replace(/\/page\/\d+$/, "") : path;
  const { page: _, ...queryWithoutPage } = query;

  if (page) {
    const queryParams = stringify(
      pageInPath
        ? queryWithoutPage
        : {
            ...queryWithoutPage,
            page: String(page),
          },
    );

    return pageInPath
      ? `${pathName}/page/${page}${queryParams !== "" ? `?${queryParams}` : ""}`
      : `${pathName}?${queryParams}`;
  } else {
    const queryParams = stringify(queryWithoutPage);

    return `${pathName}${queryParams !== "" ? `?${queryParams}` : ""}`;
  }
};

export default createPaginationUrl;
