import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import useDomain from "hooks/useDomain";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import createPaginationUrl from "./createPaginationUrl";

const RelativeLinkTags: React.FC<{
  currentPage: number;
  pageInPath?: boolean;
  totalPages: number;
}> = ({ currentPage, pageInPath, totalPages }) => {
  const { asPath } = useRouter();
  const domain = useDomain();

  const { path } = parseNextRouterAsPath(asPath);

  const prevUrl = createPaginationUrl(
    path,
    {},
    pageInPath,
    currentPage > 2 ? currentPage - 1 : undefined,
  );

  const nextUrl = createPaginationUrl(path, {}, pageInPath, currentPage + 1);

  return (
    <Head>
      {/* SEO: All pages that can possibly be paginated should have these tags */}
      {currentPage > 1 && <link rel="prev" href={`${domain}${prevUrl}`} />}
      {currentPage < totalPages && (
        <link rel="next" href={`${domain}${nextUrl}`} />
      )}
    </Head>
  );
};

export default RelativeLinkTags;
