import { useRouter } from "next/router";
import { ParsedQs, stringify } from "qs";

import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import CheckboxLink from "../CheckboxLink";

// TODO This can likely be DRY'd up with the config also defined here:
// TODO components/UnifiedShop/MobileFilterModalAndShortcutsUI/MobileFilterModalAndShortcutsUI.tsx:23
export const FULFILLMENT_METHODS = [
  { name: "Pickup", value: "pickup" },
  { name: "Delivery", value: "delivery" },
  { name: "Direct Shipping", value: "shipping" },
];
export const FULFILLMENT_LABEL_MAP = FULFILLMENT_METHODS.reduce<
  Record<string, string>
>((labelMap, { name, value }) => ({ ...labelMap, [value]: name }), {});

const FulfillmentFilters = () => {
  const { asPath } = useRouter();
  const { path, query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const selectedFulfillment = Array.isArray(query.fulfillment)
    ? (query.fulfillment as string[])
    : [];
  const { page: _page, ...otherParams } = query;

  return (
    <div className="p-md md:pb-md md:pt-0 md:px-0">
      {FULFILLMENT_METHODS.map((fulfillment) => (
        <CheckboxLink
          key={fulfillment.value}
          count={1}
          disabled={false}
          name={""}
          href={{
            pathname: path,
            query: stringify(
              {
                ...otherParams,
                fulfillment: selectedFulfillment.includes(fulfillment.value)
                  ? selectedFulfillment.filter((f) => f !== fulfillment.value)
                  : [...selectedFulfillment, fulfillment.value],
              },
              { arrayFormat: "brackets", indices: false },
            ),
          }}
          selected={
            Array.isArray(selectedFulfillment) &&
            (selectedFulfillment as string[]).includes(fulfillment.value)
          }
          showCount={false}
          value={fulfillment.value}
        >
          {fulfillment.name}
        </CheckboxLink>
      ))}
    </div>
  );
};

export default FulfillmentFilters;
