import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { ParsedQs } from "qs";

import { Query } from "custom-types/Query";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import IconSvg from "components/IconSvg";

import { getUpdatedFilterHref } from "../utils/filterUtils";

type Props = {
  className?: string;
  count?: number;
  disabled?: boolean;
  iconPath: string | null;
  label: string;
  name: string;
  value: string;
  lowerCase?: boolean;
  showCount?: boolean;
};

/**
 * A button interface that's suited for use in filter interface with a small
 * number of options (e.g. strain type, effects, etc.).
 */
const TileLink = ({
  className,
  count = 0,
  disabled = false,
  iconPath,
  label,
  showCount = true,
  lowerCase,
  name,
  value,
}: Props) => {
  const { asPath } = useRouter();
  const { query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const { [name]: appliedFilterValue = [] } = (query.filter as Query) || {};
  const selectedValues: string[] = Array.isArray(appliedFilterValue)
    ? appliedFilterValue
    : [appliedFilterValue];
  const selected = selectedValues.includes(value);

  const isDisabled = disabled || count === 0;

  return (
    <Link
      href={getUpdatedFilterHref(asPath, name, value)}
      className={classNames(
        "flex flex-col items-center border-2 text-xs py-sm w-full rounded overflow-hidden",
        {
          "border-light-grey": !selected,
          "cursor-default": isDisabled,
          "cursor-pointer": !isDisabled,
          "opacity-50": isDisabled,
        },
        className,
      )}
    >
      {iconPath ? (
        <IconSvg
          height="24"
          width="24"
          className="block mb-xs"
          filePath={iconPath}
        />
      ) : null}

      <div
        className={classNames("font-bold leading-none mb-xs truncate", {
          capitalize: !lowerCase,
          lowerCase,
        })}
      >
        {label}
      </div>
      {showCount && (
        <div className={classNames("leading-none text-grey")}>
          ({count.toLocaleString("en-us")})
        </div>
      )}
    </Link>
  );
};

export default TileLink;
