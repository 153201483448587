import React from "react";
import classNames from "classnames";
import Link from "next/link";

import Checkbox from "components/botanic/Checkbox";

type Props = {
  children: string | React.ReactNode;
  className?: string;
  count?: number;
  disabled?: boolean;
  href: { pathname: string; query: string };
  name: string;
  selected?: boolean;
  value: string;
  showCount?: boolean;
};

const CheckboxLink = ({
  children,
  className = "",
  count = 0,
  disabled = false,
  href,
  name,
  selected = false,
  showCount = true,
  value,
}: Props) => {
  const isDisabled = disabled || count === 0;

  return (
    <Link
      href={href}
      className="aria-disabled:pointer-events-none"
      aria-disabled={isDisabled}
    >
      <Checkbox
        id={`${name}_${value}`}
        data-testid={`${name}_${value}`}
        disabled={isDisabled}
        name={name}
        value={value}
        checked={selected}
        readOnly={true}
        className={classNames(
          "flex p-xs w-full",
          { "border-light-grey": !selected },
          { "cursor-pointer": !isDisabled },
          { "opacity-50": count === 0 },
          className,
        )}
      >
        <div className="flex items-center">
          {children}
          {showCount && <span className="pl-sm text-grey">({count})</span>}
        </div>
      </Checkbox>
    </Link>
  );
};

export default CheckboxLink;
