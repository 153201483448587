import { useRouter } from "next/router";
import { ParsedQs } from "qs";

import { Query } from "custom-types/Query";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import ExpandableFilterOptions from "components/ExpandableFilterOptions";

import CheckboxLink from "../CheckboxLink";
import LabelWithBadge from "../LabelWithBadge/LabelWithBadge";
import { getUpdatedFilterHref } from "../utils/filterUtils";

export const DEFAULT_VISIBLE_TAKE = 7;

export type CheckboxLinkListOption = {
  key: string;
  label: string;
  showCount?: boolean;
  value: string;
  docCount: number;
  displayName: string;
  badge?: string | null;
};

export const CheckboxLinkList: React.FC<{
  disabled?: (option: CheckboxLinkListOption) => boolean;
  options: CheckboxLinkListOption[];
  visibleTake?: number;
  name: string;
}> = ({ name, disabled, options, visibleTake = DEFAULT_VISIBLE_TAKE }) => {
  const visibleOptions = options.slice(0, visibleTake);
  const hiddenOptions = options.slice(visibleTake);

  return (
    <>
      <div
        className="overflow-auto max-h-80"
        data-testid="filter-checkbox-list"
      >
        <Checkboxes
          options={visibleOptions}
          name={name}
          disabled={disabled}
          renderCustomLabel={(filter: CheckboxLinkListOption) => (
            <LabelWithBadge
              displayName={filter.label}
              badge={filter?.badge || ""}
            />
          )}
        />

        {Boolean(hiddenOptions.length) && (
          <ExpandableFilterOptions
            expandButtonText={`Show all ${options.length.toLocaleString(
              "en-us",
            )}`}
          >
            <Checkboxes
              name={name}
              options={hiddenOptions}
              disabled={disabled}
            />
          </ExpandableFilterOptions>
        )}
      </div>
    </>
  );
};

const Checkboxes: React.FC<{
  disabled?: (option: CheckboxLinkListOption) => boolean;
  options: CheckboxLinkListOption[];
  name: string;
  renderCustomLabel?: (filter: CheckboxLinkListOption) => React.ReactNode;
}> = ({ options, name, disabled, renderCustomLabel }) => {
  const { asPath } = useRouter();
  const { query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const { [name]: appliedFilterValue = [] } = (query.filter as Query) || {};
  const selectedValues: string[] = Array.isArray(appliedFilterValue)
    ? appliedFilterValue
    : [appliedFilterValue];

  return (
    <>
      {options.map((filter) => (
        <CheckboxLink
          count={filter.docCount}
          key={filter.key}
          name={name}
          href={getUpdatedFilterHref(asPath, name, filter.value)}
          value={filter.value}
          showCount={filter?.showCount}
          selected={selectedValues.includes(filter.value)}
          disabled={disabled?.(filter)}
        >
          {renderCustomLabel ? renderCustomLabel(filter) : filter.label}
        </CheckboxLink>
      ))}
    </>
  );
};

export default CheckboxLinkList;
