export type AvailableFilter = ToggleFilterType | GroupFilter;
export type ToggleFilterType = {
  count: number;
  filterIcon?: string;
  label: string;
  labelIcon?: string | null;
  name: string;
  showCount: boolean;
  sortOrder: number;
  sublabel?: string | null;
  type: string;
};
export type GroupFilter = {
  label: string;
  name: string;
  showCount: boolean;
  sortOrder: number;
  type: string;
  values: {
    badge: string | null;
    chip: string;
    count: number;
    label: string;
    sortOrder: number;
    type: string;
    value: string;
  }[];
};
export enum FilterType {
  tileGroup = "tile_group",
  checkBoxGroup = "checkbox_group",
  toggle = "toggle",
}
export enum FilterName {
  ProductCategory = "category",
  DispensaryName = "dispensary_name",
  BrandName = "brand_name",
  StrainName = "strain_name",
  THCLevel = "strain_thc",
  CBDLevel = "strain_cbd",
  Weight = "amount",
  StrainCategory = "strain_category",
  StrainEffects = "strain_effects",
  PriceRange = "price",
  Deals = "deal_active",
  BrandVerified = "is_brand_verified",
}
