import { useEffect, useState } from "react";

import FilterSearch from "components/Filters/FilterSearch";

import CheckboxLinkList, {
  CheckboxLinkListOption,
  DEFAULT_VISIBLE_TAKE,
} from "../CheckboxLinkList/CheckboxLinkList";

interface Props {
  label: string;
  name: string;
  options: CheckboxLinkListOption[];
  selected?: (option: CheckboxLinkListOption) => boolean;
  disabled?: (option: CheckboxLinkListOption) => boolean;
}

const CheckboxSearchList = ({ label, name, options, disabled }: Props) => {
  const [subFiltersResult, setSubFiltersResult] = useState(options);
  const handleFilterSearch = (subFilters: CheckboxLinkListOption[]) =>
    setSubFiltersResult(subFilters);
  const hideFilterSearch = options.length <= DEFAULT_VISIBLE_TAKE;

  // * This effect is required in order for the list to update with new server data.
  useEffect(() => {
    setSubFiltersResult(options);
  }, [options]);

  return (
    <>
      {!hideFilterSearch && (
        <FilterSearch
          label={label}
          options={options}
          handleFilterSearch={handleFilterSearch}
          inputClasses=""
          fuseOptions={{ keys: ["key"] }}
        />
      )}
      <CheckboxLinkList
        name={name}
        options={subFiltersResult}
        disabled={disabled}
      />
    </>
  );
};

export default CheckboxSearchList;
