import { ParsedQs, stringify } from "qs";

import { Query } from "custom-types/Query";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

const reconcileExistingValuesAndNew = (
  appliedFilterValue: string | string[],
  newFilterValue: string,
): string | string[] | undefined => {
  if (Array.isArray(appliedFilterValue)) {
    // * We've already got a collection of values.
    // * We'll add or remove the requested value, depending on whether its already present.
    return appliedFilterValue.includes(newFilterValue)
      ? appliedFilterValue.filter((v) => v !== newFilterValue)
      : [...appliedFilterValue, newFilterValue];
  } else if (appliedFilterValue === newFilterValue) {
    // * This filter exists, and is a single value equal to the requested value.
    // * This likely means the user has clicked an applied filter, and thus wishes to remove it.
    return undefined;
  } else {
    // * A filter value was found, but was different than the requested value.
    // * User is likely expanding their selection for a particular filter section.
    return [appliedFilterValue, newFilterValue];
  }
};

const getUpdatedFulfillmentValue = (
  appliedFulfillmentValue: string[] | undefined,
  newFilterValue: string,
): string[] | undefined => {
  if (appliedFulfillmentValue) {
    return appliedFulfillmentValue.includes(newFilterValue)
      ? appliedFulfillmentValue.filter((v) => v !== newFilterValue)
      : [...appliedFulfillmentValue, newFilterValue];
  } else {
    return [newFilterValue];
  }
};

export const getUpdatedFilterHref = (
  asPath: string,
  name: string,
  value: string,
) => {
  const { path, query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const { filter: _filters, page: _page, ...restOfQuery } = query;
  const { [name]: appliedFilterValue, ...restOfFilters } =
    (query.filter as Query) || {};

  return {
    pathname: path,
    query: stringify(
      {
        ...restOfQuery,
        filter: {
          ...restOfFilters,
          [name]: appliedFilterValue
            ? reconcileExistingValuesAndNew(appliedFilterValue, value)
            : value,
        },
      },
      { arrayFormat: "brackets", indices: false },
    ),
  };
};

export const getUpdatedFulfillmentHref = (asPath: string, value: string) => {
  const { path, query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const { fulfillment, page: _page, ...restOfQuery } = query;
  const appliedFulfillments = Array.isArray(fulfillment)
    ? (fulfillment as string[]).filter(
        (item: string): item is string => typeof item === "string",
      )
    : typeof fulfillment === "string"
      ? [fulfillment]
      : undefined;

  return {
    pathname: path,
    query: stringify(
      {
        ...restOfQuery,
        fulfillment: getUpdatedFulfillmentValue(appliedFulfillments, value),
      },
      { arrayFormat: "brackets", indices: false },
    ),
  };
};
